import HttpService from "../HttpService";
import TeamCompetition from "@/models/sportevent/TeamCompetition";

import BaseService from "../BaseService";

class TeamCompetitionService extends BaseService<TeamCompetition> {
  public url: string;

  constructor(url = "sportevent/teamcompetition/") {
    super(url, "");
    this.url = url;
  }

  async getAllBySportevent(id: string) {
    const response = await HttpService.get<TeamCompetition[]>(
      `${this.url}bysportevent/${id}/`
    );
    return response;
  }
}
export default new TeamCompetitionService();
