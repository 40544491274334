
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import vMultiselectListbox from "vue-multiselect-listbox";
import Multiselect from "vue-multiselect";
import Society from "../../models/societymgmt/Society";

import Starter from "../../models/sportevent/Starter";
import Team from "../../models/sportevent/Team";
import StarterService from "../../services/sportevent/StarterService";
import TeamCompetitionService from "../../services/sportevent/TeamCompetitionService";
import TeamCompetition from "../../models/sportevent/TeamCompetition";
import Person from "../../models/societymgmt/Person";
import TeamService from "../../services/sportevent/TeamService";
import SporteventService from "../../services/sportevent/SporteventService";

@Component({
  components: {
    Multiselect,
    "v-multiselect-listbox": vMultiselectListbox,
  },
})
export default class StarterComponent extends Vue {
  public teamCompetition: TeamCompetition = new TeamCompetition();
  public team: Team = new Team();
  public allSocieties: Society[] = [];
  public allStarters: Starter[] = [];
  public filtedStarters: Starter[] = [];
  public isLoadingStarter = true;
  public sportevent = SporteventService.get();

  async mounted(): Promise<void> {
    const teamCompetition = await TeamCompetitionService.get(
      this.$route.params.teamCompetitionId
    );
    this.teamCompetition = new TeamCompetition(teamCompetition);
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      this.team = await TeamService.get(this.$route.params.id);
    } else {
      this.team.sportevent.id = this.sportevent.id;
      this.team.teamCompetition = this.teamCompetition;
    }
    await this.loadStarters();
  }

  save(handleback = true): void {
    //console.log("Team", this.team);
    TeamService.save(this.team).then((item: Team) => {
      if (item) {
        this.team = new Team(item);
        //console.log("Team", this.team);
        if (handleback) {
          ToastService.Success(i18n.tc("labels.saved"), "");
          this.$router.replace(`/sportevent/teamcompetitions/`);
        }
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }

  getSocietyName(society: Society): string {
    if (society.shortname !== undefined) {
      return society.shortname;
    }
    return society.name;
  }

  async loadStarters(): Promise<void> {
    this.allStarters = [];

    this.isLoadingStarter = true;
    if (this.teamCompetition.sporteventCompetition.id !== undefined) {
      this.allStarters = await StarterService.getAllBySporteventCompetition(
        this.teamCompetition.sporteventCompetition.id
      );
      //console.log("allStarters", this.allStarters);
    }
    this.loadAllSocieties();
  }

  loadAllSocieties(): void {
    this.allSocieties = [];
    this.allStarters.forEach((starter: Starter) => {
      this.allSocieties.push(new Society(starter.startsForSociety));
    });
    // distinct Societies
    this.allSocieties = this.allSocieties.filter(
      (society, i, arr) => arr.findIndex((s) => s.id === society.id) === i
    );

    this.allSocieties.sort((a: Society, b: Society) => {
      if (a.shortname < b.shortname) return -1;
      if (a.shortname > b.shortname) return 1;
      else return 0;
    });

    this.filterStarterBySociety();
  }

  filterStarterBySociety(): void {
    this.filtedStarters = this.allStarters.filter(
      (starter) => starter.startsForSociety.id === this.team.society.id
    );
    this.isLoadingStarter = false;
  }

  getStarterName(starter: Starter): string {
    const person = new Person(starter.person);
    return `${person.getFullname()} - ${starter.startsForSociety.shortname}`;
  }

  getFemaleCount(): number {
    const femaleCount = this.team.starters.filter(
      (s) => s.person.gender == "F"
    ).length;
    return femaleCount;
  }

  getMaleCount(): number {
    const maleCount = this.team.starters.filter(
      (s) => s.person.gender == "M"
    ).length;
    return maleCount;
  }
  checkParticipants(): void {
    //console.log("check");
  }
}
